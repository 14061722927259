import { Injectable, inject } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { ITicketChatSocketResponse } from '../interfaces/tickets';
import {DefaultEventsMap} from "@socket.io/component-emitter";

@Injectable({
  providedIn: 'root'
})
export class ChatSocketService {

  private _CookieService = inject(SsrCookieService);

  private socket:Socket<DefaultEventsMap, DefaultEventsMap> | null = null;


  initSocket(ticketId: string) {

    this.socket = io('https://api-dev.fastmoneytrade.com/tickets', {
      auth: {
        token: this._CookieService.get('tokenUserRefresh')
      }
    });

    // console.log("token i s socket", this._CookieService.get('tokenUserRefresh'));


    // getting the id from the event
    this.socket.on("connect", () => {
      // console.log("connected socket id", this.socket?.id);
      this.socket?.emit('USER_JOIN_TICKET', { ticket: ticketId })
    })

    // if there is connection error this will show it
    this.socket.on("connect_error", err => {
      // console.log(`connect_error due to ${err.message}`)
    });
  }

  disconnectSocket() {
    // console.log("disconnectSocket function has worked")

    this.socket?.emit("DISCONNECT");
    this.socket?.on("disconnect", (reason) => {
      // console.log(reason)
      // console.log("socket is disconnected")
    });
  }

  getChatMessages() {
    // preparing the event
    const EVENT = `NEW_TICKET_MESSAGE`;
    // console.log('EVENT: ', EVENT);

    let observable = new Observable<ITicketChatSocketResponse>(observer => {
      this.socket?.on(EVENT, (data) => { observer.next(data) });
    });
    return observable;
  }

  // getTicketsUpdates() {
  //   let observable = new Observable<any>(observer => {
  //     this.socket?.on('new-message', (data) => observer.next(data));
  //   });
  //   return observable;
  // }

}
